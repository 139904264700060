import React from "react";
import { Select, Space } from "antd";
import { getStatusDropDownStyle } from "./Status.Constant";
import { OrderStatusListProps } from "interfaces/ClientInterface/IEditOrder";
import { ISelectStatusDropdownProps } from "interfaces/ClientInterface/IStatus";
import "./StatusDropdown.scss";

const { Option } = Select;
export const StatusDropdown: React.FC<ISelectStatusDropdownProps> = ({
  defaultValue,
  onChange,
  disabled,
  loading,
  placeholder,
  allowClear,
  showSearch,
  optionMenuPlacement,
  size,
  className,
  bordered,
  dataTestSelector,
  value,
  orderStatusList,
  selectedValueKey,
}: ISelectStatusDropdownProps) => {
  return (
    <Space wrap className="status-select-container">
      <Select
        placeholder={placeholder}
        value={value}
        className={className}
        size={size || "middle"}
        disabled={disabled}
        defaultValue={defaultValue}
        onChange={onChange}
        placement={optionMenuPlacement || "bottomLeft"}
        loading={loading}
        allowClear={allowClear}
        bordered={bordered}
        showSearch={showSearch}
        style={getStatusDropDownStyle(orderStatusList, value || "", selectedValueKey)}
        dropdownStyle={{ minWidth: "14%", padding: "0px", borderRadius: "0px" }}
        onClick={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
        }}
        data-test-selector={dataTestSelector}
      >
        {orderStatusList.map((x: OrderStatusListProps) => {
          return (
            <Option
              style={{
                backgroundColor: x.CPBackColor,
                color: x.CPTextColor,
                borderRadius: "0px",
              }}
              OrderStateName={x.OrderStateName}
              OmsOrderStateId={x.OmsOrderStateId}
              key={x.OmsOrderStateId}
            >
              {x.OrderStateName}
            </Option>
          );
        })}
      </Select>
    </Space>
  );
};

export default StatusDropdown;
