import React from "react";

/** -----------------------Lazy Loading -----------------------------------*/

export const LoginPage = React.lazy(() => import("pages/LoginPage"));
export const DashboardPage = React.lazy(() => import("pages/DashboardPage"));
export const ResetPassword = React.lazy(() => import("components/ResetPassword/ResetPassword"));
export const ForgotPassword = React.lazy(() => import("components/ForgotPassword/ForgotPassword"));
export const Logout = React.lazy(() => import("./Logout"));
export const UserProfile = React.lazy(
  () => import("components/PerlickComponents/UserProfile/PerlickUserProfile")
);
export const ChangePassword = React.lazy(
  () => import("components/PerlickComponents/UserProfile/PerlickChangePassword")
);
export const AuthLayoutFallback = React.lazy(() => import("layouts/AuthLayout/AuthLayout"));

/** Calculator */
export const FirstCalculator = React.lazy(
  () => import("components/PerlickComponents/Calculator/PerlickFirstCalculator")
);

export const SecondCalculator = React.lazy(
  () => import("components/PerlickComponents/Calculator/PerlickSecondCalculator")
);

export const ThirdCalculator = React.lazy(
  () => import("components/PerlickComponents/Calculator/PerlickThirdCalculator")
);

/**Customer*/
export const CustomerDetails = React.lazy(
  () => import("components/PerlickComponents/Customers/PerlickCustomerDetails")
);
export const CustomerList = React.lazy(
  () => import("components/PerlickComponents/Customers/PerlickCustomersList")
);

/** Fresh Desk */
export const CreateTicket = React.lazy(
  () => import("components/PerlickComponents/FreshDesk/PerlickCreateTicket")
);
export const TicketList = React.lazy(
  () => import("components/PerlickComponents/FreshDesk/PerlickTicketList")
);

export const FreshDeskDetails = React.lazy(
  () => import("components/PerlickComponents/FreshDesk/PerlickFreshDeskDetails")
);
/**Invoices */
export const InvoiceDetails = React.lazy(
  () => import("components/PerlickComponents/Invoices/PerlickInvoiceDetails")
);
export const InvoiceList = React.lazy(
  () => import("components/PerlickComponents/Invoices/PerlickInvoiceList")
);

/** Orders */
export const OrderDetails = React.lazy(
  () => import("components/PerlickComponents/Order/PerlickOrderDetails")
);
export const OrderList = React.lazy(
  () => import("components/PerlickComponents/Order/PerlickOrderList")
);

/** Payments */
export const PaymentList = React.lazy(
  () => import("components/PerlickComponents/Payments/PerlickPaymentList")
);

/** RMA */
export const RMAList = React.lazy(() => import("components/PerlickComponents/RMA/PerlickRMAList"));

/** Sales Rep */
export const Hierarchy = React.lazy(
  () => import("components/PerlickComponents/SalesRep/PerlickHierarchy")
);

export const SalesRepList = React.lazy(
  () => import("components/PerlickComponents/SalesRep/PerlickSalesRepList")
);

/** Service Calls */
export const ServiceList = React.lazy(
  () => import("components/PerlickComponents/Servicecalls/PerlickServiceList")
);

/** Service Parts */
export const ServicePart = React.lazy(
  () => import("components/PerlickComponents/ServicePart/PerlickServicePart")
);

/** Shipments */
export const Shipment = React.lazy(
  () => import("components/PerlickComponents/Shipments/PerlickShipment")
);

/** Warranty */
export const Warranty = React.lazy(
  () => import("components/PerlickComponents/Warranty/PerlickWarranty")
);

/** 404 */
export const NotFound = React.lazy(
  () => import("components/PerlickComponents/NotFound/Perlick404")
);

/** Packing Slip */
export const PackingSlipDetails = React.lazy(
  () => import("components/PerlickComponents/Packing Slip/PerlickPackingSlipDetails")
);

export const UserRegistration = React.lazy(
  () => import("components/PerlickComponents/UserRegistration/UserRegistration")
);

export const PerlickNews = React.lazy(
  () => import("components/PerlickComponents/News/PerlickNews")
);
