/* eslint-disable no-console */
import { httpApi } from "api/http.api";
import axios from "axios";
import { perlickConstant } from "config/Perlick.Constant";
import { LOGIN_API } from "config/constant";
import { ILoginPayLoad, ILoginResponse } from "interfaces/ClientInterface/ILogin";

export interface AuthData {
  email: string;
  password: string;
}

export interface SignUpRequest {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface ResetPasswordRequest {
  email: string;
}

export interface SecurityCodePayload {
  code: string;
}

export interface NewPasswordData {
  newPassword: string;
}

export interface RefreshClient {
  client_id: string;
}

export interface RefreshTokenRequest {
  refreshToken: string;
}

export const login = (loginPayload: ILoginPayLoad): Promise<ILoginResponse> => {
  // const axiosInstance = axios.create();
  return axios
    .post<ILoginResponse>(LOGIN_API, {
      PortalId: perlickConstant.PORTAL_ID,
      User: loginPayload.user,
      iswebstoreuser: loginPayload.iswebstoreuser,
    })
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.log("login error");
      throw err;
    });
};

export const signUp = (signUpData: SignUpRequest): Promise<undefined> =>
  httpApi.post<undefined>("signUp", { ...signUpData }).then(({ data }) => data);

export const refreshToken = (refreshToken: RefreshTokenRequest): Promise<ILoginResponse> =>
  httpApi
    .post<ILoginResponse>("CmsAuth?callType=persistent", { ...refreshToken })
    .then(({ data }) => data);

export const setClientToken = (request: { client_id: string }): Promise<ILoginResponse> =>
  httpApi
    .post<ILoginResponse>("CmsAuth?callType=set_client_of_account", { ...request })
    .then(({ data }) => data);

export const resetPassword = (resetPasswordPayload: ResetPasswordRequest): Promise<undefined> =>
  httpApi.post<undefined>("forgotPassword", { ...resetPasswordPayload }).then(({ data }) => data);

export const verifySecurityCode = (securityCodePayload: SecurityCodePayload): Promise<undefined> =>
  httpApi
    .post<undefined>("verifySecurityCode", { ...securityCodePayload })
    .then(({ data }) => data);

export const setNewPassword = (newPasswordData: NewPasswordData): Promise<undefined> =>
  httpApi.post<undefined>("setNewPassword", { ...newPasswordData }).then(({ data }) => data);
