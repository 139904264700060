/* eslint-disable max-len */
import React, { useState } from "react";
import { Button, Input, Space, Popover } from "antd";
import { translate } from "i18n";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { setPriceOrderNotesDetails } from "store/slices/editOrder.slice";
import { createNote } from "api/services/orderNote.api";
import { notificationController } from "components/Common/Notification/Notification";

const { TextArea } = Input;

interface IPriceNoteChange {
  OrderProductId: string;
}

export const PriceNoteChange: React.FC<IPriceNoteChange> = ({
  OrderProductId,
}: IPriceNoteChange) => {
  const [notes, setNotes] = useState("");
  const dispatch = useAppDispatch();
  const priceNoteChangeInfo = useAppSelector((state) => state.editOrder.priceNoteChange);
  const orderData = useAppSelector((state) => state.editOrder.orderDetails);
  const onCancelHandler = () => {
    setNotes("");
    dispatch(
      setPriceOrderNotesDetails({
        OrderProductId: "",
        visible: false,
      })
    );
  };

  const addNoteHandler = () => {
    const createNotePayload = {
      OrderId: orderData.OrderId,
      ClassId: orderData.ClassId,
      UserId: orderData.UserId,
      Note: notes,
      OrderNoteIds: "",
      IsDeleted: false,
      Message: "",
      ViewFilter: "",
    };
    createNote(createNotePayload)
      .then((response) => {
        if (response.IsSuccess) {
          notificationController.success({
            message: translate("editOrder.orderNoteCreatedSuccessMessage"),
            placement: "top",
          });
        }
        onCancelHandler();
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
      });
  };

  return (
    <Popover
      title=""
      content={
        <Space className="m-0" size={16} direction="vertical">
          <TextArea
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            placeholder={translate("editOrder.addNotesPlaceholder")}
            rows={3}
            data-test-selector="txtNotes"
            style={{ width: 280 }}
          />
          <Space>
            <Button
              size="middle"
              type="primary"
              data-test-selector="btnAddNote"
              onClick={addNoteHandler}
            >
              {translate("editOrder.addNoteBtn")}
            </Button>
            <Button size="middle" onClick={onCancelHandler}>
              {translate("editOrder.cancelBtn")}
            </Button>
          </Space>
        </Space>
      }
      trigger="click"
      open={priceNoteChangeInfo?.visible && priceNoteChangeInfo.OrderProductId == OrderProductId}
      placement="topRight"
    />
  );
};
