export const API_GATEWAY_URL = process.env.REACT_APP_GATEWAY_URL;
export const AUTH_GATEWAY_URL = process.env.REACT_APP_GATEWAY_URL;
export const LOGIN_COOKIE = "loginCookie";
export const REMEMBER_COOKIE = "isRememberEnabled";
export const COOKIES_EXPIRES = 2;
export const LOGIN_API = `${AUTH_GATEWAY_URL}/users/login`;
export const ORDER_LIST_API = `${API_GATEWAY_URL}/order/list`;
export const BAD_REQUEST = "ERR_BAD_REQUEST";
export const FORGOT_PASSWORD_API = `${API_GATEWAY_URL}/users/forgotpassword`;
export const PORTAL_ID = 7;
export const BASE_URL = process.env.REACT_APP_BASE_URL as string;
export const RESET_PASSWORD_VERIFY_API = `${API_GATEWAY_URL}/users/verifyresetpasswordlinkstatus`;
export const RESET_PASSWORD_API = `${API_GATEWAY_URL}/users/changepassword`;
export const GET_ORDER_API = `${API_GATEWAY_URL}/order/getorderdetailswithflag`;
export const DOLLAR = "$";
export const PLUS = "+";
export const UPDATE_ORDER_FLAG_API = `${API_GATEWAY_URL}/order/updateorderflag`;
export const DELETE_ORDER_FLAG_API = `${API_GATEWAY_URL}/order/deleteorderflag`;
export const DELETE_ORDER_USER_GROUP_API = `${API_GATEWAY_URL}/order/deleteorderusergroup`;
/* eslint-disable max-len */
export const ORDER_STATUS_LIST_API = `${API_GATEWAY_URL}/manageorderstatus/getstatuslistbyclasscode`;
export const GET_USER_DETAIL_API = `${API_GATEWAY_URL}/users/getuserdetailbyid`;
export const STORE_LIST_API = `${API_GATEWAY_URL}/typeahead/gettypeaheadresponse`;
export const ORDER_TYPE_LIST_API = `${API_GATEWAY_URL}/ordertype/list`;
export const ACCOUNT_LIST_API = `${API_GATEWAY_URL}/account/list`;
export const USER_LIST_API = `${API_GATEWAY_URL}/customeraccount/list`;
export const CREATE_ORDER_API = `${API_GATEWAY_URL}/Order/Create`;
export const ORDER_ORIGIN = "Commerce Portal";
export const ORDER_CLASS = "Orders";
export const TYPEAHEAD_TYPE = "Stores";
export const TYPEAHEAD_TYPE_NAME = "StoreList";
export const UPDATE_ORDER_OWNER_API = `${API_GATEWAY_URL}/order/updateorderowner`;
export const PRODUCT_LIST_API = `${API_GATEWAY_URL}/search/fulltextsearch`;
export const GET_PRODUCT_API = `${API_GATEWAY_URL}/publishproduct/get`;
export const SHIPTO_API = `${API_GATEWAY_URL}/customer/addresslist`;
export const GET_SHIPPING_METHODS_API = `${API_GATEWAY_URL}/shoppingcarts/getshippingestimates`;
export const CREATE_SHIPPING_ADDRESS_API = `${API_GATEWAY_URL}/customer/createcustomeraddress`;
export const GET_SHIPPING_ADDRESS_API = `${API_GATEWAY_URL}/shipping/getorderaddress`;
export const UPDATE_SHIPPING_ADDRESS_API = `${API_GATEWAY_URL}/customer/updatecustomeraddress`;
export const CREATE_ORDER_SHIPPING_API = `${API_GATEWAY_URL}/shipping/createshipping`;
export const UPDATE_ORDER_API = `${API_GATEWAY_URL}/order/update`;
export const UPDATE_ORDER_STATUS_API = `${API_GATEWAY_URL}/order/updateorderstatusbyorderid`;
export const UNASSOCIATED_ORDER_FLAG_API = `${API_GATEWAY_URL}/order/getunassociatedorderflaglist`;
export const ADD_ORDER_FLAG_API = `${API_GATEWAY_URL}/order/AddOrderFlag`;
export const ORDERLOG_LIST_URL = `${API_GATEWAY_URL}/orderLog/getorderloglist`;
export const USERGROUP_LIST_API = `${API_GATEWAY_URL}/usergroups/list`;
export const SAVEDVIEW_API = `${API_GATEWAY_URL}/applicationsettings/createnewview`;
export const GETSAVEDVIEW_API = `${API_GATEWAY_URL}/applicationsettings/getviewbyid`;
export const GETSAVEDVIEWLIST_API = `${API_GATEWAY_URL}/applicationsettings`;
export const DELETESAVEDVIEW_API = `${API_GATEWAY_URL}/applicationsettings/deleteview`;
export const CREATE_ORDER_NOTE_API = `${API_GATEWAY_URL}/orderlog/create`;
export const GET_PRODUCTLISTBYORDERID_API = `${API_GATEWAY_URL}/order/getorderlineitems`;
export const CREATE_PAYMENT_ADDRESS_API = `${API_GATEWAY_URL}/customer/createcustomeraddress`;
export const CREATE_ORDER_PAYMENT = `${API_GATEWAY_URL}/payment/createorderpayment`;
export const GET_PAYMENT_ADDRESS_API = `${API_GATEWAY_URL}/payment/getorderaddress`;
export const UPDATE_PAYMENT_ADDRESS_API = `${API_GATEWAY_URL}/customer/updatecustomeraddress`;
export const DELET_ORDERNOTE_API = `${API_GATEWAY_URL}/orderlog/deleteordernote`;
export const UPDATE_ORDERNOTE_API = `${API_GATEWAY_URL}/orderlog/updateordernote`;
export const GENERATE_INVOICE_API = `${API_GATEWAY_URL}/order/generateinvoice`;
export const PRODUCTION_PUBLISH_STATE = "PRODUCTION";
export const TOP_PLACEMENT = "top";
export const CATEGORY_LIST_API = `${API_GATEWAY_URL}/webstorecategory/getcategorydetails`;
export const GET_CONFIG_PRODUCT_API = `${API_GATEWAY_URL}/publishproduct/getconfigurableproduct`;
export const ADD_PRODUCT_API = `${API_GATEWAY_URL}/product/addlineitem`;
export const GET_SHIPPING_DETAILS_API = `${API_GATEWAY_URL}/shipping/getshippingdetailsbyorderid`;
export const ORDER_NOTE_EXPORT = `${API_GATEWAY_URL}/orderlog/exportordernote`;
export const GET_DATA_FOR_QUICKVIEW_API = `${API_GATEWAY_URL}/order/getorderdetailsforquickview`;
export const DATE_FORMATE = "YYYY/MM/DD";
export const GET_PAYMENT_DETAILS_API = `${API_GATEWAY_URL}/payment/getpaymentdetailsbyorderid`;
export const GET_ORDERNOTEFILTERS = `${API_GATEWAY_URL}/orderlog/getorderlogfilterlist`;
export const GET_ORDER_PRODUCT_API = `${API_GATEWAY_URL}/product/getorderlineitemdetails`;
export const GET_ORDER_STATUS_BUTTON_LIST_API = `${API_GATEWAY_URL}/order/getorderstatusbuttonlist`;
export const GET_BUTTON_DETAIL_BY_ID_API = `${API_GATEWAY_URL}/order/getbuttondetailbyid`;
export const GET_ORDER_STATUS_BY_ID_API = `${API_GATEWAY_URL}/order/getorderstatusbyid`;
export const GET_ORDER_CLASS_BUTTON_LIST_API = `${API_GATEWAY_URL}/order/getorderclassbuttonlist`;
// eslint-disable-next-line max-len
export const GET_ORDER_PRODUCT_CATALOG_PROFILE_API = `${API_GATEWAY_URL}/product/getprofiledetails`;
export const DELETE_LINEITEM_API = `${API_GATEWAY_URL}/order/deletelineitems`;
export const CALCULATE_API = `${API_GATEWAY_URL}/order/calculate`;
export const DEFAULT_EMPTY_GUID = "00000000-0000-0000-0000-000000000000";
export const DELEGATES = "Delegates";
export const MANAGERS = "Managers";
export const CPSmartView = "CPSmartView";
export const OrderNumber = "Order Number";
export const Account = "Account";
export const UserName = "UserName";
export const OrderStateName = "OrderStateName";
export const OrderTotal = "OrderTotal";
export const Editsave = "Editsave";
export const Delete = "Delete";
export const Priority1 = "Priority1";
export const Priority2 = "Priority2";
export const Priority3 = "Priority3";
export const MultipleShare = "MultipleShare";
export const THUMBNAIL_IMAGE_PATH = `${process.env.ZNODE_API_URL as string}/Data/Media/Thumbnail/`;
export const ALLORDER = "AllOrder";
export const ASSIGNTOFULLNAME = "AssignToFullName";
export const CREATEDDATE = "CreatedDate";
export const MODIFIEDDATE = "ModifiedDate";
export const AllStores = "All Stores";
export const Age = "Age";
export const ViewAll = "View All";
