import React, { useEffect } from "react";
import { AppRouter } from "routes/AppRouter";
import { MsalProvider } from "@azure/msal-react";
import "./assets/styles/App.scss";
import { pca } from "../src/authConfig";
import Cookies from "js-cookie";
import { getAPIToken } from "api/http.api";

const MainContent = () => {
  return <AppRouter />;
};

function App() {
  useEffect(() => {
    const myToken = Cookies.get("DataT");
    if (myToken) {
      getAPIToken(myToken);
    }
  }, []);

  return (
    <MsalProvider instance={pca}>
      <MainContent />
    </MsalProvider>
  );
}

export default App;
