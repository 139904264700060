/* eslint-disable max-lines-per-function */
import React, { useEffect, useRef } from "react";
import { Layout, Row, Col, Menu, Space, Button, Avatar, theme, Tooltip, Typography } from "antd";
import type { MenuProps } from "antd";
import { Header } from "antd/es/layout/layout";
import { MenuOutlined, DownOutlined } from "@ant-design/icons";
import { Link, NavLink, useLocation } from "react-router-dom";
import { themeSetting } from "config/defaultSettings";
import {
  clearLocalStorage,
  deleteToken,
  deleteUser,
  getUserDetails,
  removeAllCookies,
} from "api/services/localStorage.service";
import { ROUTES_PATH } from "routes/Routes.Constant";
import { translate } from "i18n";
import { menuData } from "./Header.Utils";
import { siderProps } from "interfaces/ClientInterface/IHeader";
const { Title } = Typography;
const { useToken } = theme;
import Logo from "../../Logo/Logo";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import logo from "assets/images/logo.png";
import { perlickConstant } from "config/Perlick.Constant";

const HeaderComponent = (props: siderProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const activityTimeoutRef = useRef<any>(null);
  const location = useLocation();
  const { token } = useToken();
  const { collapsed, setCollapsed } = props;
  const userData = getUserDetails() ? JSON.parse(getUserDetails() || "") : {};
  const initials = userData?.FirstName?.[0]?.toUpperCase() + userData?.LastName?.[0]?.toUpperCase();
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const handleLogout = () => {
    deleteToken();
    deleteUser();
    removeAllCookies();
    clearLocalStorage();
    if (isAuthenticated) {
      instance.logoutRedirect();
    } else {
      const win: Window = window;
      win.location = "/auth/login";
    }
  };

  useEffect(() => {
    activityTimeoutRef.current = setTimeout(() => {
      handleLogout();
    }, +perlickConstant.RefreshTokenSession * 60 * 1000);

    const activityEvents = ["mousedown", "mousemove", "keypress", "scroll"];
    const handleActivity = () => {
      clearTimeout(activityTimeoutRef.current); // Reset the timeout on activity

      activityTimeoutRef.current = setTimeout(() => {
        handleLogout();
      }, +perlickConstant.RefreshTokenSession * 60 * 1000);
    };

    activityEvents.forEach((event) => {
      document.addEventListener(event, handleActivity);
    });

    handleActivity();

    return () => {
      // clearTimeout(activityTimeoutRef.current); // Reset the timeout on activity
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const items: MenuProps["items"] = [
    {
      key: "user-profile",
      label: (
        <Space>
          <Tooltip title={`${userData?.FirstName} ${userData?.LastName}`} placement="bottomLeft">
            <Avatar
              style={{
                backgroundColor: token.colorPrimary,
                verticalAlign: "middle",
                color: token.colorBgContainer,
              }}
              size="small"
              gap={2}
            >
              {initials}
            </Avatar>
          </Tooltip>
          <DownOutlined style={{ color: themeSetting.token.colorIcon }} />
        </Space>
      ),
      children: [
        {
          label: (
            <Link to={`${ROUTES_PATH.USER_PROFILE}`} title={translate("common.titleMyProfile")}>
              {translate("common.titleProfile")}
            </Link>
          ),
          key: "my-profile",
        },

        {
          label: (
            <p onClick={handleLogout} title={translate("common.titleLogout")} className="m-0">
              {translate("common.titleLogout")}
            </p>
          ),
          key: "logout",
        },
      ],
    },
  ];

  return (
    <Layout className="main-header" style={{ background: themeSetting.token.colorTheme }}>
      <Row>
        <Col span={24}>
          <Title level={4} className="header-title">
            PERLICK PORTAL
          </Title>
          <Header
            className="layout-header-style text-center"
            style={{ background: themeSetting.token.colorTheme }}
          >
            <Link to="/" target="_self">
              <Logo
                imageWidth={"120px"}
                imageHeight={"auto"}
                isPreview={false}
                className={"headerLogo"}
                imageUrl={logo}
              />
            </Link>
          </Header>
        </Col>
      </Row>
      <Row className="align-center text-white" justify={"space-between"}>
        <Col span={18}>
          <div className="d-flex header-menu-container">
            <Button
              type="text"
              className="px-15"
              icon={collapsed ? <MenuOutlined /> : <MenuOutlined />}
              onClick={() => setCollapsed(!collapsed)}
            />
            <div className="d-flex align-center header-menu">
              {menuData.map((item, id) => {
                return (
                  <>
                    <NavLink
                      to={item.link}
                      key={id}
                      className={item.link == location.pathname ? "header-data" : ""}
                      target={item.target}
                    >
                      {item.name}
                    </NavLink>
                  </>
                );
              })}
            </div>
          </div>
        </Col>

        <Col span={2} className="d-flex align-center justify-end">
          {/* <Title level={4} className="m-0 text-white px-15">
            <BellOutlined />
          </Title>
          <Title level={4} className="m-0 text-white">
            <MailOutlined />
          </Title> */}
          <Menu
            triggerSubMenuAction="click"
            mode="horizontal"
            items={items}
            style={{ background: themeSetting.token.colorTheme }}
            className="justify-right"
          />
        </Col>
      </Row>
    </Layout>
  );
};

export default HeaderComponent;
