/* eslint-disable max-len */
const datatypes = {
  string: "string",
  number: "number",
  datetime: "datetime",
  boolean: "boolean",
};

export const perlickConstant = {
  RefreshTokenSession: process.env.REACT_APP_EXPIRATION_TIME
    ? process.env.REACT_APP_EXPIRATION_TIME
    : 60,
  DefaultPageIndex: 1,
  DefaultNumberOfRows: 10,
  TitleNodata: "No data Found",
  Age: "Age",
  RMATitle: "RMA's",
  NeedBy: "Need By",
  Customer: "Customer",
  OrderStatus: "Order Status",
  OrderTotal: "Order Total",
  OrderAmount: "Order Amount",
  OrderInformation: "Order Information",
  MyCommission: "My Commission",
  OrderDate: "Order Date",
  Terms: "Terms",
  PONumber: "PO Number",
  SalesPerson: "Sales Person",
  FOB: "FOB",
  TaxId: "Tax Id",
  JobName: "Job Name",
  Subtotal: "Sub Total",
  ShippingHandling: "Shipping & Handling",
  Tax: "Tax",
  Total: "Total",
  RepSplit: "Rep Split",
  Parts: "Parts",
  RepCode: "Rep Code",
  RepName: "Rep Name",
  titleShippingAddresses: "Shipping Addresses",
  titleOrder: "ORDER",
  KIT: "KIT",
  Description: "Description",
  AdditionalInfo: "Additional Info",
  PartNumber: "Part Number",
  Price: "Price",
  Quantity: "Quantity",
  UOM: "UOM",
  TotalPrice: "Total Price",
  Status: "Status",
  ShipDate: "Ship Date",
  ShipQty: "Ship Qty",
  ShipVia: "Ship Via",
  TitlePackingSlip: "Packing Slip",
  Attachments: "Attachments",
  Invoices: "Invoices",
  TitleInvoiceNo: "Invoice Number",
  InvoiceDate: "Invoice Date",
  DueDate: "Due Date",
  OrderNo: "Order Number",
  Amount: "Amount",
  BalanceDue: "Balance Due",
  Action: "Action",
  Quantities: "Quantities",
  ShippingDate: "Shipping Date",
  UnitPrice: "Unit Price",
  LineValue: "Line Value",
  ReturnNo: "Return Number",
  ReturnLine: "Return Line(#)",
  ReturnDate: "Return Date",
  SKU: "SKU",
  RevisionLvl: "Revision Level",
  ReturnedQTY: "Qty Returned",
  Received: "Received",
  OrderQuantity: "Order Quantity",
  TrackingNumber: "Tracking Number",
  InvoiceStatus: "Invoice Status",
  Name: "Name",
  Address1: "Address1",
  Address2: "Address2",
  Address3: "Address3",
  City: "City",
  Province: "Province",
  PostalCode: "Postal Code",
  Country: "Country",
  CustomerID: "Customer ID",
  CustomerName: "Customer Name",
  InvoiceAddress: "Invoice Address",
  SalesRepID: "Sales Rep ID",
  SalesRep: "Sales Rep",
  Email: "Email",
  ColumnTitle: "Filter Results",
  Filter: "Filter",
  Tool: "Tools",
  Delivered: "Delivered",
  Backorderd: "Backorderd",
  DueBy: "Due By",
  ResellerID: "Reseller ID",
  TaxExemptReference: "Tax Exempt Reference",
  Shipments: "Shipments",
  ShipmentDate: "Shipment Date",
  Open: "OPEN",
  Close: "CLOSED",
  TitleInvoiceDetailLines: "Lines" /** Calculator constants */,
  TitleSoldTo: "Sold To",
  TitleShipTo: "Ship To",
  TitlePackingSlipInformation: "Packing Slip Information",
  CalculatorFlash: "Flash Chill",
  Calculator1Header: "Perlick Corporation - Standard Century System Power Pak Selection",
  StandardCenturySystem:
    "This calculator recommends the model of the Perlick Century Power Pak based on the defined criteria entered to ensure sufficient cooling capacity to maintain product temperature between the walk-in cooler and the dispensing head. ",
  DropDownMessage: "Please select drop down value.",
  FaucetLocation: "Faucet Location",
  TrunkHousingSize: "Trunk Housing Size",
  Length: "Length (ft) ",
  MaximumAmbientTemperature: "Maximum Ambient Temperature",
  NumberofFaucets: "Number of Faucets",
  ErrorMessageForNumber: "Value should contain just number.",
  ErrorMessageForNumberAndDecimal: "Value should contain number or decimal.",
  ErrorMessageForLengthOfNumber5: "Count should be less than 5.",
  ErrorMessageForLengthOfNumber4: "Count should be less than 4.",
  ErrorMessageForLengthOfNumber3: "Count should be less than 3.",
  CoolantConnectorLineSet:
    "Length of Coolant Connector Line Set (Power pak to trunk housing(s)) (ft)",
  AmbientTemperature: "Ambient temperature at power pak",
  PerlickTowerMessage: "If you wish to use frozen towers, Please contact Perlick",
  Calculator2Header: "Perlick Corporation - Arctic Pour Refrigeration Deck Sizing",
  ArcticPourHeader:
    " This calculator recommends the model of the Perlick Arctic Pour Refrigeration Deck based on the defined criteria entered to ensure sufficient cooling capacity to maintain product temperature between the walk-in cooler \nand the dispensing head. ",
  FlashChillErrorMessage: "Please select flash chill value",
  CoolantConnectorGlycolBath:
    "Length of coolant connector line set (Glycol bath to trunk housings(s)) (ft)",
  CoolantConnectorRefrigerationDeck:
    "Length of coolant connector line set (Refrigeration deck to glycol bath) (ft)",
  DesiredVoltage: "Desired Voltage",
  CoolantLineType: "Coolant Line Type",
  Id: "Id",
  Subject: "Subject",
  RequesterName: "Requester Name",
  CreatedDate: "Created Date",
  RequiredInput: "Please enter valid input.",
  ModelNumber: "Model Number",
  Type: "Warranty Type",
  StartDate: "Start Date",
  EndDate: "End Date",
  ParentAccount: "Parent Account",
  ChildAccount: "Child Account",
  AccountNumber: "Account Number",
  AccountName: "Account Name",
  TicketId: "Ticket Id",
  TitleCustomerDetails: "Customer Detail",
  AccountInformation: "Account Information",
  Review: "Review",
  CustomerId: "Customer Id",
  PeriodBalances: "Period Balances",
  AgedBalance: "Aged Balances",
  TitleContacts: "Contacts",
  PhoneNumber: "Phone Number",
  DaysFilter_15: "Last 15 Days",
  DaysFilter_30: "Last 30 Days",
  DaysFilter_1Y: "Last 1 Year",
  DaysFilter_All: "All",
  MinimumDate: "01/01/1920",
  Balance: "Total Balance (Including Outstanding)",
  CreditLimit: "Credit Limit",
  UnallocatedCash: "Unallocated Cash",
  PeriodtoDatePurchase: "Period to Date Purchase",
  CurrentYearPurchases: "Current Year Purchases",
  MinOrderValue: "Min Order Value",
  LastPaymentDate: "Last Payment Date",
  LastPaymentValue: "Last Payment Amount",
  ViewOrders: "View Orders",
  ViewShipments: "View Shipments",
  ViewInvoices: "View Invoices",
  ViewPayments: "View Payments",
  ViewRMAs: "View RMA's",
  ViewServiceCalls: "View Service Calls",
  Range_1To15: "1-15",
  Range_16To30: "16-30",
  Range_31To60: "31-60",
  Range_61To90: "61-90",
  Range_Over90: "Over 90",
  Range_Current: "Current",
  ZeroAmount: "$0.00",
  STATUS_CONFIRM_ORDER: "CONFIRMED ORDER",
  STATUS_PROCESSING_ORDER: "PROCESSING ORDER",
  STATUS_BUILD_IN_PROGRESS: "BUILD IN PROGRESS",
  STATUS_PARTIALLY_SHIPPED: "PARTIALLY SHIPPED",
  STATUS_SHIPPED_IN_FULL: "SHIPPED IN FULL",
  RECEIVED_PROCESSING_ORDER: "Processing Order",
  RECEIVED_BUILD_IN_PROGRESS: "Build In Progress",
  RECEIVED_PARTIALLY_SHIPPED: "Partially Shipped",
  RECEIVED_SHIPPED_IN_FULL: "Shipped in full",
  FirstName: "First Name",
  LastName: "Last Name",
  UserName_Email: "Username/ Email",
  VerfifyRequesterName: "Please enter valid email address",
  VerfifyName: "Please enter valid name",
  CreatePassword: "Create Password",
  ConfirmPassword: "Confirm Password",
  SalesRepName: "Sales Rep Name",
  USerCreated:
    "Username created successfully. Please log in with the credentials to access your account.",
  EmailExists: "This username already exists. Please choose a different username.",
  InternalServerError: "Request failed with status code 500",
  CreateTicket: "Customer Experience Support",
  HowCanWeHelpYouText: "How Can We Help You?",
  CreateTicketSuccessMessage: "Ticket has been created successfully.",
  FileExceedMessage: "File size exceeds 20MB limit.",
  HowCanWeHelpYouText_WithStart: "How Can We Help You?",
  IssueWithOrder: "Issue with an Order",
  SONumber: "SO Number",
  PORTAL_ID: 7,
  UserVerificationType: "NoVerificationCode",
  UserGlobalAttributesCode: "PerlickSalesRepName",
  TicketDetails: "Ticket Details",
  TicketHeader: "Missing Part - Perlick under counter fridge",
  AllFieldsRequired: "All fields are required",
  FieldRequired: "This field is required",
  DefaultPassword: "admin12345",
  EmailSuccess: "Email Sent Successfully",
  Datatype: datatypes,
  PaymentDate: "Payment Date",
  Reference: "Reference",
  Call: "Call#",
  CallType: "Call Type",
  RequestedDate: "Requested Date",
  ScheduleDate: "Scheduled Date",
  ActualDate: "Actual Date",
  ServiceStatus: "Service Status",
  PackingSlipActionToolTip: "Send Packing Slip Email",
  CustomerNavigationFilter: "Customer_Id",
  filterEqualOperator: "eq",
  PerlickAPICustomerFelid: "Customer_CustID",
  FilterNavigationText: "List Viewing For Customer Id",
  ClearAllFilter: "Clear All Filters",
  InvalidDateFormat: "Invalid date format. The date format should be MM/DD/YYYY.",
  lastUpdatedTime: "Last Updated Time",
  EmailFailed: "Email Delivery Failure",
  AccountDisabled: "Your account has been disabled. Please contact a Site Administrator.",
  InvalidUsernamePassword: "Invalid Username or Password.",
  AccountLoginFailed: "Login Failed as Account is still not verified.",
  TwoAttemptsToLogIn:
    "You are left with TWO more login attempts. If it is wrong, your account will be disabled.",
  OneAttemptToLogIn:
    "You are left with ONE more login attempt. If it is wrong, your account will be disabled.",
  TrackingURL: "TrackingURL",
  Calculator3Header: "Perlick Corporation - Century System Design",
  System: "System",
  TrunkHousing: "No. Of trunk housings",
  Manifolding: "Allow Manifolding",
  Altitude: "Altitude (ft)",
  DimensionStatus: "Dimension Status",
  InsulationType: "Insulation Type",
  Tubing: "Tubing I.D.",
  PreferredPress: "Preferred Press. Source",
  RequiredPress: "Required Press. Source",
  InstallerID: "Installer ID",
  EngineerInitials: "Engineer Initials",
  SendRMAEmail: "Send RMA Email",
  SomethingWentWrongMessage: "Something Went Wrong. Please Try After Sometime.",
  InvalidServiceStatus: "Entered Service Status Is Invalid, Please Enter Valid Service Status.",
  PackingId: "packingid",
  AlertAndNotification: "alertandnotification",
  ImportantLinks: "importantlinks",
  InvalidfileFormat: "Selected files are invalid. Please select a valid files upto 20MB.",
  MiscellaneousCharge: "Miscellaneous Charges",
  WarrantyNodata: "Data Not Found For This Warranty Number - ",
  NewsConstantBlock: "PerlickNews",
  SystemType: "System Type",
  CoolerTemperature: "Cooler Temperature",
  CarbonationLevel: "Carbonation Level",
  Ddim: "Ddim",
  Edim: "Edim",
  Fdim: "Fdim",
  LineLength: "Line Length",
  SysNo: "sysno",
  SysNoCount: "sysnocnt",
  SendEmailErrorCode: 9920,
  FreshdeskFileUploadNote:
    "Only txt, docx, doc, ppt, pdf, csv, png, svg, jpeg, jpg, xls, xlsx, msg, pst, eml files are allowed upto 20MB.",
  MaxFileSize_20MB: 20 * 1024 * 1024,
  FileUploadSizeExceedMessage: "Please select valid file type and file size (Less than 20MB).",
  SelectOrderNumber: "Please Select Order Number",
  SelectPackingSlipNumber: "Please Select Packing Slip Number",
  SelectInvoiceNumber: "Please Select Invoice Number",
  PrivateNoteLabel: "Added A Private Note",
  PleaseSelectRecordMessage: "Please select records before continuing with the action.",
  MergedIntoTicketMessage: "merged into this ticket",
  MergedFromTicketMessage: "merged from ticket",
  closedTicketMessage: "this ticket is closed and merged into ticket",
  CenturySystemHeader:
    "This calculator defines the correct number of Perlick restrictor segments, the correct applied pressure to pressurize the system, and the correct media to pressurize the system to ensure a pour rate of 1 gallon per minute flow rate. ",
  SecondCalCErrorMsg:
    "Please check the value in Trunk Housing Size and Number of Faucets (Both should have at least one value) and there should be at least one value in the coolant connector line.",
  OrderNeedByDate: "Need By Date",
  FreshDesk: "Your Customer Experience",
};
