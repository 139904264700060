/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import React, { SetStateAction, Dispatch } from "react";
import {
  CopyOutlined,
  EditOutlined,
  CaretRightOutlined,
  CheckCircleFilled,
  UndoOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { Image, Input, Space, Typography, Tooltip, Button } from "antd";
import {
  IEditOrderPermissions,
  IProductList,
  OrderStatusListProps,
} from "interfaces/ClientInterface/IEditOrder";
import { camelize } from "utils";
import "./EditOrder.scss";
import { themeSetting } from "config/defaultSettings";
import { IDuplicateLineItem } from "interfaces/ClientInterface/IOrderList";
import { translate } from "i18n";
import { THUMBNAIL_IMAGE_PATH } from "config/constant";
import { PureAbility } from "@casl/ability";
import NoImage from "../../assets/images/no-image.png";
import StatusDropdown from "components/Common/Status/StatusDropdown";
import StatusTag from "components/Common/Status/StatusTag";
import { getStatusDropDownStyle } from "components/Common/Status/Status.Constant";
import { PriceNoteChange } from "./PriceChangeNote";

const { Text } = Typography;

const renderInput = (
  value: string,
  // eslint-disable-next-line no-unused-vars
  onChangeHandler1: (text: string, field: string, index: number, eventName: string) => void,
  index: number,
  field: string,
  isDisable?: boolean
) => {
  return (
    <Input
      size="small"
      value={value}
      style={{ width: "80px" }}
      onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
        onChangeHandler1(e?.target?.value, field, index, "onChange")
      }
      type="number"
      data-test-selector={`txt${camelize(field)}`}
      onBlur={(e: React.ChangeEvent<HTMLInputElement>): void =>
        onChangeHandler1(e?.target?.value, field, index, "onBlur")
      }
      min={0}
      disabled={isDisable}
      onKeyDown={(event) => {
        const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight"];
        const key = event.key;
        if (field == "onChangePrice") {
          allowedKeys.push(".");
        }
        // Allow only digits and specific control keys
        if (!/^\d$/.test(key) && !allowedKeys.includes(key)) {
          event.preventDefault();
        }
      }}
    />
  );
};

const renderFlagDetails = (record: IProductList, field: string) => {
  const totalCount = record?.OrderFlag?.length;

  const checkedCount = record?.OrderFlag?.filter((item) => {
    return item.IsChecked;
  })?.length;
  // const overriedenCount = record.OrderFlag.filter((item) => {
  //   return item.IsChecked;
  // })?.length;

  return (
    <>
      {field == "OkStatus" && (
        <CheckCircleFilled
          shape="circle"
          style={{ color: checkedCount == totalCount ? "#48AA44" : "#f7a400" }}
          className="check-icon"
        />
      )}
      {field == "Flags" && (
        <div className="txt-bold">
          {checkedCount} / {totalCount}
        </div>
      )}
    </>
  );
};

export const columns = (
  // eslint-disable-next-line no-unused-vars
  onChangeHandler: (text: string, field: string, index: number) => void,
  onChangeHandlerForTotalUpdate: (
    // eslint-disable-next-line no-unused-vars
    text: string,
    // eslint-disable-next-line no-unused-vars
    field: string,
    // eslint-disable-next-line no-unused-vars
    index: number,
    // eslint-disable-next-line no-unused-vars
    eventName: string
  ) => void,
  orderStatusList: OrderStatusListProps[],
  isExpandable: boolean,
  // eslint-disable-next-line no-unused-vars
  onEditItemPress: (lineItemProductId: string, configurableSKU: string) => void,
  setIsDuplicateOrder: Dispatch<SetStateAction<IDuplicateLineItem>>,
  // eslint-disable-next-line no-unused-vars
  onDeleteItemPress: (lineItemProductId: string, deleteTypeFlag: string) => void,
  ability: PureAbility<[string, string]>,
  productList: IProductList[],
  isEditPermission: boolean,
) => {
  const canChangePrices = ability.can("ChangePrices", "Orders");
  const canChangeOrderLineStatus = ability.can("ChangeOrderLineStatus", "Orders");
  const recordCount =
    productList?.length !== 0
      ? productList?.reduce(
        (total: number, product: IProductList) => total + Number(product?.Quantity),
        0
      )
      : 0;
  const cols = [
    {
      title: translate("editOrder.tblImageColumnLabel"),
      dataIndex: "image",
      key: "image",
      render: (text: string, record: IProductList) => {
        return {
          children: (
            <Image width={50} src={THUMBNAIL_IMAGE_PATH + record.ProductImage} fallback={NoImage} />
          ),
        };
      },
    },
    {
      title: translate("editOrder.tblProductNameColumnLabel"),
      dataIndex: "ProductName",
      key: "ProductName",
      width: "10rem",
      render: (text: string, record: IProductList) => {
        return {
          children: (
            <Space direction="vertical">
              <div>{text}</div>
              {isExpandable ? (
                <>
                  {record?.OrderProductAttributeValue?.map((item) => {
                    return (
                      <>
                        {(item.AttributeCode == "Color" || item.AttributeCode == "Size") && (
                          <Space>
                            <CaretRightOutlined />
                            <Text>{`${item.AttributeCode}: ${item.AttributeValue}`}</Text>
                          </Space>
                        )}
                      </>
                    );
                  })}
                </>
              ) : null}
            </Space>
          ),
        };
      },
    },
    {
      title: translate("editOrder.tblSkuColumnLabel"),
      dataIndex: "SKU",
      key: "SKU",
      render: (text: string) => {
        return {
          children: (
            <Space direction="vertical" size={2}>
              <div>{text}</div>
            </Space>
          ),
        };
      },
    },
    {
      title: translate("editOrder.tblOkColumnLabel"),
      dataIndex: "",
      key: "",
      render: (text: string, record: IProductList) => {
        return renderFlagDetails(record, "OkStatus"); //<CheckCircleFilled shape="circle" className="check-icon" />;
      },
    },
    {
      title: translate("editOrder.tblFlagsColumnLabel"),
      dataIndex: "Flags",
      key: "Flags",
      width: "5rem",
      render: (text: string, record: IProductList) => {
        return renderFlagDetails(record, "Flags");
      },
    },
    {
      title: translate("editOrder.tblStatusColumnLabel"),
      dataIndex: "Status",
      key: "Status",
      render: (text: string, record: IProductList, index: number) => {
        const value = text ? text : record?.OrderStateName;
        return isEditPermission && canChangeOrderLineStatus ? (
          <StatusDropdown
            orderStatusList={orderStatusList}
            size="small"
            value={value || undefined}
            placeholder="Select"
            bordered={false}
            data-test-selector={"drpStatus"}
            onChange={(selectedText: string): void =>
              onChangeHandler(selectedText, "Status", index)
            }
            selectedValueKey={text ? "OmsOrderStateId" : "OrderStateName"}
          />
        ) : (
          <StatusTag
            text={record?.OrderStateName || ""}
            tagStyle={getStatusDropDownStyle(
              orderStatusList,
              record?.OrderStateName || "",
              "OrderStateName"
            )}
            dataTestSelector="tagOrderStatus"
          />
        );
      },
    },

    {
      title: (
        <div>{`${translate("editOrder.tblQtyColumnLabel")} ${
          recordCount && productList.length !== 0 ? `(${recordCount})` : ""
        }`}</div>
      ),
      dataIndex: "Quantity",
      key: "Quantity",
      render: (text: string, record: IProductList, index: number) => {
        return renderInput(
          record?.onChangeQuantity || "",
          onChangeHandlerForTotalUpdate,
          index,
          "onChangeQuantity",
          !isEditPermission
        );
      },
    },

    {
      title: translate("editOrder.tblPriceColumnLabel"),
      dataIndex: "Price",
      key: "Price",
      render: (text: string, record: IProductList, index: number) => {
        return (
          //{record.IsPriceUpdated}
          <Space>
            {renderInput(
              record?.onChangePrice || "",
              onChangeHandlerForTotalUpdate,
              index,
              "onChangePrice",
              isEditPermission ? !canChangePrices : true
            )}
            <PriceNoteChange OrderProductId={record.OrderProductId || ""} />
            {record.IsPriceUpdated ? (
              <Tooltip title="Reset Price">
                <Button
                  type="text"
                  className="no-padding-antd-button p-0"
                  onClick={() => onChangeHandlerForTotalUpdate("", "", index, "onClick")}
                  disabled={!canChangePrices}
                >
                  <UndoOutlined className="refresh-icon" />
                </Button>
              </Tooltip>
            ) : null}
          </Space>
        );
      },
    },
    {
      title: translate("editOrder.tblTotalColumnLabel"),
      dataIndex: "total",
      key: "Total",

      render: (_: Text, record: IProductList) => {
        const total =
          parseFloat(record.onChangeQuantity || "") * parseFloat(record.onChangePrice || "");
        return <div className="txt-bold">${total ? total?.toFixed(2) : 0}</div>;
      },
    },
    {
      title: translate("editOrder.tblActionsColumnLabel"),
      key: "action",
      dataIndex: "Actions",
      render: (_: Text, record: IProductList) => {
        return (
          <>
            <Space size="middle">
              <Tooltip title={translate("editOrder.tooltipEdit")}>
                <EditOutlined
                  onClick={() => onEditItemPress(record?.OrderProductId || "", record?.SKU || "")}
                  style={{ color: themeSetting.token.colorIcon }}
                />
              </Tooltip>

              <Tooltip title={translate("editOrder.tooltipDuplicate")}>
                <CopyOutlined
                  style={{ color: themeSetting.token.colorIcon }}
                  onClick={() => {
                    setIsDuplicateOrder({
                      OrderProductId: record?.OrderProductId || "",
                      visible: true,
                    });
                  }}
                />
              </Tooltip>

              <Tooltip title={translate("editOrder.tooltipDelete")}>
                <CloseCircleOutlined
                  onClick={() =>
                    onDeleteItemPress(record?.OrderProductId || "", DELETE_TYPES.DELETE)
                  }
                  style={{ color: themeSetting.token.colorIcon }}
                />
              </Tooltip>
            </Space>
          </>
        );
      },
    },
  ];

  return isEditPermission
    ? cols
    : cols.filter((el) => {
      return el.dataIndex != "Actions";
    });
};

/**
 * TODO: Need to remove this constant data after API Integration
 */
export const orderStatusData = [
  {
    name: "Order Created",
    value: "03/14/2023 10:15 AM",
  },
  {
    name: "Production Date:",
    value: "03/18/2023 10:00 AM",
  },
  {
    name: "Ship Date:",
    value: "03/21/2023 8:15 AM",
  },
  {
    name: "In Hands Date:",
    value: "03/25/2023 11:25 AM",
  },
  {
    name: "Finalized:",
    value: "03/15/2023 9:05 AM",
  },
  {
    name: "Created By:",
    value: "Jan Cramer",
  },
  {
    name: "Origin:",
    value: "Pre Processor",
  },
  {
    name: "Last Updated:",
    value: "03/14/2023 11:25 AM",
  },
  {
    name: "Last Updated By",
    value: "Joe Smith",
  },
];

/**
 * TODO: Need to remove this constant data after API Integration
 */
export const orderSummary = ["Store", "Account", "User", "Order Type"];

/**
 * TODO: Need to remove this constant data after API Integration
 */
export const orderTotalData = [
  {
    name: "Sub-total",
    value: "$100.00",
  },
  {
    name: "Discount",
    value: "-$6.00",
  },
  {
    name: "Tax",
    value: "-$6.67",
  },
  {
    name: "Handling Fee",
    value: "$2.67",
  },
  {
    name: "Shipping Cost",
    value: "-$6.00",
  },
  {
    name: "CSR Discount",
    value: "-$20.00",
  },
];

export const DELETE_TYPES = {
  CLEAR_ALL: "clearAll",
  DELETE: "delete",
  DELETE_MULTIPLE: "deleteMultiple",
};

export const checkEditOrderPermission = (editOrderPermission: IEditOrderPermissions) => {
  let allowUserToEdit = false;
  if (editOrderPermission.EditAllOrder) {
    allowUserToEdit = true;
  } else {
    if (editOrderPermission.EditMyTeamOrders) {
      allowUserToEdit = true;
    }
    if (editOrderPermission.EditMyOrders) {
      allowUserToEdit = true;
    }
  }

  if (allowUserToEdit && editOrderPermission.allowEditing) {
    allowUserToEdit = true;
  } else {
    allowUserToEdit = false;
  }

  return allowUserToEdit;
};
