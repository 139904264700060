import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { perlickConstant } from "config/Perlick.Constant";
import {
  IOrderListRequest,
  IOrderListResponse,
} from "interfaces/ClientInterface/PerlickClientInterface/IOrder";

export interface IOrderDetails {
  OrderActivePageNumber: number;
  orderResponseList: IOrderListResponse[];
  orderId: number;
  orderStatus: boolean;
  orderStatusValue: number;
  orderList: IOrderListRequest;
  orderListTime: string;
  orderTotalPage: number;
}

const initialState: IOrderDetails = {
  OrderActivePageNumber: perlickConstant.DefaultPageIndex,
  orderResponseList: [],
  orderId: 0,
  orderStatus: false,
  orderStatusValue: 0,
  orderList: {
    SalesRepId: "",
    OrderStartDate: "",
    OrderEndDate: "",
    OrderNum: "",
    PageNo: 1,
    Rows: 10,
    CustID: "",
    CalculatedOrderStatus: "",
    PONo: "",
    FromOrderDate: "",
    ToOrderDate: "",
    OrderStatus: "",
    OrderNoFrom: "",
    OrderNoTo: "",
    OrderAmountFrom: "",
    OrderAmountTo: "",
    OrderName: "",
    JobName: "",
    CustomerName: "",
    ShipToName: "",
    ShipToAddress: "",
    NeedByDateFrom: "",
    NeedByDateTo: "",
    SerialNumber: "",
    filtersList: [],
    Refresh: false,
  },
  orderListTime: "",
  orderTotalPage: 0,
};

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    getOrderId: (state, action: PayloadAction<number>) => {
      state.orderId = action.payload;
    },
    getOpenStatusModel: (state, action: PayloadAction<number>) => {
      state.orderStatusValue = action.payload;
    },
    getOrderModelOpen: (state, action: PayloadAction<boolean>) => {
      state.orderStatus = action.payload;
    },
    getOrderList: (state, action: PayloadAction<IOrderListResponse[]>) => {
      state.orderResponseList = action.payload;
    },
    getOrderRefreshTime: (state, action: PayloadAction<string>) => {
      state.orderListTime = action.payload;
    },
    getOrderTotalPage: (state, action: PayloadAction<number>) => {
      state.orderTotalPage = action.payload;
    },
    getOrderPageNumber: (state, action: PayloadAction<number>) => {
      state.OrderActivePageNumber = action.payload;
    },
  },
});

export const {
  getOrderId,
  getOpenStatusModel,
  getOrderModelOpen,
  getOrderList,
  getOrderRefreshTime,
  getOrderTotalPage,
  getOrderPageNumber,
} = orderSlice.actions;

export default orderSlice.reducer;
