import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { withLoading } from "hocs/withLoading.hoc";
import RequireAuth from "./RequireAuth";
import MainLayout from "layouts/MainLayout/MainLayout";
import { ROUTES_PATH } from "./Routes.Constant";
import { LazyLoader } from "components/Common/LazyLoader/LazyLoader";
import {
  LoginPage,
  DashboardPage,
  ResetPassword,
  ForgotPassword,
  FirstCalculator,
  SecondCalculator,
  CustomerDetails,
  CustomerList,
  CreateTicket,
  TicketList,
  FreshDeskDetails,
  InvoiceDetails,
  InvoiceList,
  OrderDetails,
  OrderList,
  PaymentList,
  RMAList,
  Hierarchy,
  ServiceList,
  ServicePart,
  Shipment,
  Warranty,
  Logout,
  ChangePassword,
  UserProfile,
  AuthLayoutFallback,
  NotFound,
  PackingSlipDetails,
  SalesRepList,
  UserRegistration,
  PerlickNews,
  ThirdCalculator,
} from "./routes";
import { PERLICK_ROUTES_PATH } from "./PerlickRoutes.Constant";
const Dashboard = withLoading(DashboardPage);
const LogoutFallback = withLoading(Logout);
const PerlickPackingSlip = withLoading(PackingSlipDetails);
export const DASHBOARD_PATH = "/";

interface IRoute {
  path: string;
  component: JSX.Element;
}

const routes: IRoute[] = [
  {
    path: "/",
    component: <Dashboard />,
  },
  {
    path: PERLICK_ROUTES_PATH.DASHBOARD,
    component: <Dashboard />,
  },
  {
    path: PERLICK_ROUTES_PATH.CALCULATOR_1,
    component: <FirstCalculator />,
  },
  {
    path: PERLICK_ROUTES_PATH.CALCULATOR_2,
    component: <SecondCalculator />,
  },
  {
    path: PERLICK_ROUTES_PATH.CUSTOMER_DETAILS,
    component: <CustomerDetails />,
  },
  {
    path: PERLICK_ROUTES_PATH.CUSTOMER,
    component: <CustomerList />,
  },
  {
    path: PERLICK_ROUTES_PATH.CREATE_TICKET,
    component: <CreateTicket />,
  },
  {
    path: PERLICK_ROUTES_PATH.TICKET,
    component: <TicketList />,
  },
  {
    path: PERLICK_ROUTES_PATH.INVOICE_DETAIL,
    component: <InvoiceDetails />,
  },
  {
    path: PERLICK_ROUTES_PATH.FRESHDESK_DETAILS,
    component: < FreshDeskDetails />,
  },
  {
    path: PERLICK_ROUTES_PATH.INVOICE,
    component: <InvoiceList />,
  },
  {
    path: PERLICK_ROUTES_PATH.ORDER_DETAILS,
    component: <OrderDetails />,
  },
  {
    path: PERLICK_ROUTES_PATH.ORDERS,
    component: <OrderList />,
  },
  {
    path: PERLICK_ROUTES_PATH.PAYMENTS,
    component: <PaymentList />,
  },
  {
    path: PERLICK_ROUTES_PATH.RMA,
    component: <RMAList />,
  },
  {
    path: PERLICK_ROUTES_PATH.HIERARCHY,
    component: <Hierarchy />,
  },
  {
    path: PERLICK_ROUTES_PATH.SERVICE,
    component: <ServiceList />,
  },
  {
    path: PERLICK_ROUTES_PATH.SERVICE_PART,
    component: <ServicePart />,
  },
  {
    path: PERLICK_ROUTES_PATH.SHIPMENT,
    component: <Shipment />,
  },
  {
    path: PERLICK_ROUTES_PATH.WARRANTY,
    component: <Warranty />,
  },
  {
    path: PERLICK_ROUTES_PATH.CHANGE_PASSWORD,
    component: <ChangePassword />,
  },
  {
    path: PERLICK_ROUTES_PATH.CHANGE_PROFILE,
    component: <UserProfile />,
  },
  {
    path: "*",
    component: <NotFound />,
  },
  {
    path: PERLICK_ROUTES_PATH.PACKING_SLIP,
    component: <PerlickPackingSlip />,
  },
  {
    path: PERLICK_ROUTES_PATH.SALES_REP_LIST,
    component: <SalesRepList />,
  },
  {
    path: PERLICK_ROUTES_PATH.Perlick_News,
    component : <PerlickNews/>,
  },
  {
    path: PERLICK_ROUTES_PATH.CALCULATOR_3,
    component : <ThirdCalculator />,
  }
];

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  const suspenseWithLoader = (children: React.ReactNode, delay?: number) => {
    return (
      <Suspense fallback={<LazyLoader {...(delay ? { delay: delay } : {})} />}>{children}</Suspense>
    );
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path={DASHBOARD_PATH} element={protectedLayout}>
          {routes.map((item: IRoute, id: number) => {
            return (
              <Route key={id} path={item.path} element={suspenseWithLoader(item.component, 800)} />
            );
          })}
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path={`${ROUTES_PATH.LOGIN}`} element={suspenseWithLoader(<LoginPage />)} />
        </Route>
        <Route path={`${ROUTES_PATH.LOGOUT}`} element={<LogoutFallback />} />
        <Route
          path={`${PERLICK_ROUTES_PATH.USER_REGISTRATION }`}
          element={suspenseWithLoader(<UserRegistration />)}
        />
        <Route
          path={`${ROUTES_PATH.FORGOT_PASSWORD}`}
          element={suspenseWithLoader(<ForgotPassword />)}
        />
        <Route
          path={`${ROUTES_PATH.RESET_PASSWORD}`}
          element={suspenseWithLoader(<ResetPassword />)}
        />
      </Routes>
    </BrowserRouter>
  );
};
