import { PERLICK_ROUTES_PATH } from "routes/PerlickRoutes.Constant";

export const menuData = [
  {
    name: "Perlick News",
    link: PERLICK_ROUTES_PATH.Perlick_News,
    target: "_self",
  },
  {
    name: "Commercial Products",
    link: PERLICK_ROUTES_PATH.COMMERCIAL_PRODUCTS,
    target: "_blank",
  },
  {
    name: "Residential Products",
    link: PERLICK_ROUTES_PATH.RESIDENTIAL_PRODUCTS,
    target: "_blank",
  },
  {
    name: "Parts",
    link: PERLICK_ROUTES_PATH.PARTS,
    target: "_blank",
  },
  {
    name: "PowerPak Calculator",
    link: PERLICK_ROUTES_PATH.CALCULATOR_1,
    target: "_self",
  },
  {
    name: "Arctic Calculator",
    link: PERLICK_ROUTES_PATH.CALCULATOR_2,
    target: "_self",
  },
  {
    name: "Century System Design",
    link: "/CenturySystemDesign",
  }
];
