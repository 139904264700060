import React from "react";
import { Tag as AntdTag } from "antd";
import { TagComponentProps } from "interfaces/ClientInterface/ITag";
import "./Tag.scss";

export const TagComponent: React.FC<TagComponentProps> = ({
  text,
  icon,
  tagContentClass,
  tagColor,
  tagContainerClass,
  closable,
  onCloseClick,
  dataTestSelector,
  isRounded = false,
  closeIcon,
  id,
}: TagComponentProps) => {
  const onCancelPress = () => {
    onCloseClick && onCloseClick(id as string);
  };
  return (
    <div className="tag-container">
      <AntdTag
        icon={icon}
        color={tagColor}
        className={tagContainerClass}
        closable={closable}
        onClose={onCancelPress}
        data-test-selector={dataTestSelector}
        style={isRounded ? { borderRadius: "15px" } : {}}
        closeIcon={closeIcon}
      >
        <span style={{ color: tagContentClass }}>{text}</span>
      </AntdTag>
    </div>
  );
};

export default TagComponent;
