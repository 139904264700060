import React, { FC } from "react";
import { Col, Popover, Row, Space, Spin, Table, Tooltip, Typography } from "antd";
import { IQuickView } from "interfaces/ClientInterface/IQuickViewOrder";
import { CloseOutlined } from "@ant-design/icons";

import "./QuickViewOrder.scss";
import { columns } from "./QuickViewOrder.Constant";
import TagComponent from "../Tag/Tag";
import { translate } from "i18n";

const { Text } = Typography;

const QuickView: FC<IQuickView> = ({ open, setOpen, children, data, loading }) => {
  const renderItem = (key: string, value: string | React.ReactNode, isFirst?: boolean) => {
    return (
      <Col span={isFirst ? 9 : 7}>
        <Row>
          <Col span={isFirst ? 10 : 12} className="key-style">
            {key}
          </Col>
          <Col span={12}>{value || "N/A"}</Col>
        </Row>
      </Col>
    );
  };

  const cols = columns(data?.ProductsList);

  return (
    <Popover
      title=""
      transitionName=""
      content={
        <Space className="quick-view-container m-0" size={16} direction="vertical">
          {loading ? (
            <Space align="center" className="d-flex justify-center align-center empty-view-height">
              <Spin tip="Loading" size="small" />
            </Space>
          ) : (
            <>
              <Row className="d-flex justify-between">
                <Text className="txt-bold text-uppercase pl-5">
                  {translate("common.textOrderSummary")}
                </Text>
                <Tooltip title="Close">
                  <CloseOutlined onClick={() => setOpen(false)} />
                </Tooltip>
              </Row>
              <Row className="header" style={{ justifyContent: "space-between" }}>
                {renderItem("Order Number:", data?.OrderNumber, true)}
                {renderItem("Order Total:", data?.OrderTotal)}
                {renderItem(
                  "Order Status:",
                  <TagComponent
                    text={data?.OrderStateName}
                    tagContentClass="tag-content-green"
                    tagContainerClass="tag-container-green"
                    tagColor="#B7EB8F"
                    dataTestSelector="tagOrderStatus"
                  />
                )}
              </Row>
              <Row className="padding-8-hr" style={{ justifyContent: "space-between" }}>
                {renderItem("Customer Name:", data?.CustomerName, true)}
                {renderItem("Order Date:", data?.OrderDate)}
                {renderItem("Owner:", data?.Owner)}
              </Row>
              <Row className="padding-8-hr" style={{ justifyContent: "space-between" }}>
                {renderItem("Company Name:", data?.CompanyName, true)}
                {renderItem("Order Type:", data?.OrderType)}
                {renderItem("Last Updated:", data?.LastUpdated)}
              </Row>
              <Row className="padding-8-hr" style={{ justifyContent: "space-between" }}>
                {renderItem("Shipping To:", data?.ShippingTo, true)}
                {renderItem("Shipping Method:", data?.ShippingMethod)}
                {renderItem("Fulfilled Date:", data?.FulfilledDate)}
              </Row>
              <div className="product-list-table">
                <Table
                  size="small"
                  dataSource={data?.ProductsList}
                  columns={cols}
                  pagination={false}
                  // scroll={{ x: "max-content" }}
                  data-test-selector="tblQuickView"
                />
              </div>
            </>
          )}
        </Space>
      }
      trigger="click"
      open={open}
      onOpenChange={setOpen}
      placement="left"
    >
      {children}
    </Popover>
  );
};
export default QuickView;
